import React from "react";
import { SvgIcon } from "@material-ui/core";

const WordPressIcon = (props) => {
  return (
    <SvgIcon>
      <path
        style={{
          fillRule: "evenodd",
        }}
        d="M 12 1.679688 C 14.691406 1.679688 17.140625 2.710938 18.980469 4.402344 C 18.078125 4.378906 17.179688 4.914062 17.140625 6.136719 C 17.066406 8.617188 20.105469 9.144531 18.46875 14.128906 L 17.113281 18.25 L 13.425781 7.382812 C 13.367188 7.214844 13.433594 7.121094 13.640625 7.121094 L 14.683594 7.121094 C 14.738281 7.121094 14.78125 7.074219 14.78125 7.019531 L 14.78125 6.398438 C 14.78125 6.34375 14.738281 6.292969 14.683594 6.296875 C 12.710938 6.382812 10.746094 6.382812 8.792969 6.296875 C 8.738281 6.292969 8.695312 6.34375 8.695312 6.398438 L 8.695312 7.019531 C 8.695312 7.074219 8.742188 7.121094 8.792969 7.121094 L 9.84375 7.121094 C 10.078125 7.121094 10.140625 7.199219 10.195312 7.347656 L 11.710938 11.492188 L 9.433594 18.378906 L 5.683594 7.339844 C 5.636719 7.203125 5.695312 7.121094 5.828125 7.121094 L 7.023438 7.121094 C 7.078125 7.121094 7.121094 7.074219 7.121094 7.019531 L 7.121094 6.398438 C 7.121094 6.34375 7.078125 6.292969 7.023438 6.296875 C 5.769531 6.363281 4.511719 6.378906 3.339844 6.390625 C 5.179688 3.554688 8.371094 1.679688 12 1.679688 Z M 21.074219 7.085938 C 21.867188 8.546875 22.316406 10.21875 22.316406 12 C 22.316406 15.902344 20.148438 19.300781 16.953125 21.050781 L 20.167969 11.9375 C 20.667969 10.527344 21.128906 8.621094 21.074219 7.085938 Z M 15.453125 21.722656 C 14.375 22.109375 13.210938 22.316406 12 22.316406 C 10.957031 22.316406 9.949219 22.160156 9 21.875 L 12.175781 12.765625 Z M 7.585938 21.328125 C 4.09375 19.671875 1.679688 16.117188 1.679688 12 C 1.679688 10.472656 2.011719 9.023438 2.609375 7.71875 Z M 12 0.710938 C 18.230469 0.710938 23.285156 5.765625 23.285156 12 C 23.285156 18.230469 18.230469 23.285156 12 23.285156 C 5.765625 23.285156 0.714844 18.230469 0.714844 12 C 0.714844 5.765625 5.765625 0.710938 12 0.710938 Z M 12 0 C 18.625 0 24 5.375 24 12 C 24 18.625 18.625 24 12 24 C 5.375 24 0 18.625 0 12 C 0 5.375 5.375 0 12 0 Z M 12 0 "
      />
    </SvgIcon>
  );
};

export default WordPressIcon;
